const API_URL = "https://api.dev.checkup.dev.solidstudio.io";

export default {
    DEFAULT_REGION: "eu-west-2",
    cognito: {
        USER_POOL_ID: "eu-west-2_etpVKWMBa",
        APP_CLIENT_ID: "5l25hv0f8sqdg5cdjm3munmnnm",
        IDENTITY_POOL_ID: "eu-west-2:470d28ee-e0b7-4329-bc39-3bf5eae6fe9d"
    },
    s3: {
        BUCKET: "tcup-app-uploads"
    },
    apis: {
        usersApi: {
            URL: `${API_URL}/users-service`
        },
        questionsApi: {
            URL: `${API_URL}/questions-service`
        }
    }
};